import AppController from "./controller/app-controller";
import CookieConsentConfig from "./utils/cookieconsent-config";
document.addEventListener("DOMContentLoaded",async function () {
	const app = new AppController();
    // CookieConsent = await import('vanilla-cookieconsent');
    // CookieConsentConfig.language.default = document.querySelector('html').getAttribute('lang').split('-')[0];
    // CookieConsent.run(CookieConsentConfig);
    // console.log(CookieConsent);
    
    setTimeout(() => {
        if (document.querySelector('.axeptio_mount')) {

            document.querySelector('.axeptio_mount').style.zIndex = "1" 
        } 
    }, 200);
    var loaderKey = 'spirit-loader',
        lastLoader = parseInt(localStorage.getItem(loaderKey), 10),
        nowDate = Date.now();

    /* Every 10 min */
    if (lastLoader && nowDate < lastLoader + 1000 * 60 * 10) {
        return;
    }

    /* Mark loader */
    localStorage.setItem(loaderKey, nowDate);
	document.body.setAttribute('data-loader-show',1);
    /* Trigger loader */
    setTimeout(function(){
		document.body.removeAttribute('data-loader-show');
    },2000);

});