class GridDebug {
    constructor(nbCols, currentState = 1) {
        this.dom = {
            gridDebug: undefined,
            button: undefined
        };
        this.nbCols = nbCols;
        this.currentState = currentState;
        this.maxState = 3

        this.createDom();
        
        document.addEventListener('keyup', (e) => { if (e.code == 'KeyG') this.toggle(); });
    }

    createDom () {
        this.dom.gridDebug = document.createElement('div');
        this.dom.gridDebug.className = 'gridDebug';
        this.dom.gridDebug.setAttribute('data-state', this.currentState);
        this.dom.gridDebug.innerHTML = '<div class="container"><div class="row"></div></div>';

        this.dom.button = document.createElement('button');
        this.dom.button.className = 'toggleGrid';
        this.dom.button.innerHTML = '<svg width="21" height="20" viewBox="0 0 21 20" version="1" xmlns="http://www.w3.org/2000/svg"> <path d="M0 0h5v20H0zM16 0h5v20h-5zM8 0h5v20H8z" /> </svg>';

        let row = this.dom.gridDebug.querySelector('.row');

        for (let index = 0; index < this.nbCols; index++) {
            let col = document.createElement('div');
            col.className = 'col-1';
            col.innerHTML = '<div class="colOverlay"><span>' + (index+1) + '</span></div>';

            row.appendChild(col);
        }

        document.body.appendChild(this.dom.gridDebug);
        document.body.appendChild(this.dom.button);

        this.dom.button.addEventListener('click', () => { this.toggle() }); 
    }

    toggle () {
        this.currentState = this.currentState === this.maxState ? 0 : this.currentState + 1;
        this.dom.gridDebug.setAttribute('data-state', this.currentState);
    }

}

export default GridDebug;